<template>
  <div style="margin-top:84px">
    <goods-info :skc-code="nftid" :nft="true" />
  </div>
</template>

<script>
import GoodsInfo from '../components/GoodsInfo.vue'
export default {
  components: { GoodsInfo },
  data() {
    return {
      nftid: ''
    }
  },
  created() {
    this.nftid = this.$route.params.id
  },
  mounted() {
  }
}
</script>
